import React, { useState } from "react";
import "./ArrayEdit.css";

const ArrayEdit = ({ initialArray = [], type = "string", onDone, onCancel }) => {
    const [items, setItems] = useState([...initialArray]);

    const handleChange = (value, index) => {
        if (
            (type === "number" && isNaN(value)) ||
            (type === "string" && typeof value !== "string")
        ) return;

        const updatedItems = [...items];
        updatedItems[index] = type === "number" ? Number(value) : value;
        setItems(updatedItems);
    };

    const handleAdd = () => {
        setItems([...items, type === "number" ? 0 : ""]);
    };

    const handleRemove = (index) => {
        const updatedItems = items.filter((_, i) => i !== index);
        setItems(updatedItems);
    };

    const handleDone = () => {
        onDone(items);
    };

    return (
        <div className="arrayedit-overlay">
            <div className="arrayedit-box">
                <h3>Edit Items</h3>
                {items.map((item, index) => (
                    <div key={index} className="arrayedit-row">
                        <input
                            type={type === "number" ? "number" : "text"}
                            value={item}
                            onChange={(e) => handleChange(e.target.value, index)}
                            className="arrayedit-input"
                        />
                        <button
                            onClick={() => handleRemove(index)}
                            className="arrayedit-remove-btn"
                            title="Remove"
                        >
                            ✕
                        </button>
                    </div>
                ))}

                <div className="arrayedit-buttons">
                    <button onClick={handleAdd}>Add</button>
                    <button onClick={handleDone}>Done</button>
                    <button onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default ArrayEdit;
